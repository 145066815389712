import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Route, useHistory, withRouter} from "react-router-dom";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const permissionMap = new Map([
	['/nomination', 'nominations'],
	['/telstra-staff', 'channel-partner-manager'],
	['/admin', 'channel-partner-admin'],
]);
const SecureRoute = ({component: Component, path, componentProps, ...rest}) => {
	const user = useSelector(({auth}) => auth.user );

	const history = useHistory();
	const location = useLocation();

	const [showComponent, setShowComponent] = useState(false);

	const checkPermission = (path) => {
		const match = Array.from(permissionMap.keys()).find(prefix => path.startsWith(prefix));
		if(!match){
			return true;
		}
		return user?.userData?.groups && user?.userData?.groups.includes(permissionMap.get(match));
	}

	useEffect(() => {
		if (user && user.authenticated) {
			if(checkPermission(path)) {
				setShowComponent(true);
			} else{
				history.push("/");
			}
			return;
		}
		const handleAuth = async () => {
			history.push("/login?referrer=" + location.pathname);
		};
		handleAuth();
		//eslint-disable-next-line
	}, [user]);

	const render = props => user && user.authenticated && showComponent ? <Component {...props} {...componentProps} /> : null;

	return <Route path={path} children={render} {...rest} />;
};

SecureRoute.propTypes = {
	component: PropTypes.oneOfType([PropTypes.any])
		.isRequired,
	path: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string)
	]).isRequired
};

export default withRouter(SecureRoute);
