import styled from "styled-components";
import {colour} from "../../../cssVariables";
import {Dialog} from "@material-ui/core";

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 400px;
  }

  &.error {
    .MuiDialogTitle-root {
      color: ${colour.redError}
    }
  }
  
  .MuiDialogContent-root {
    .MuiTypography-colorTextSecondary {
      color: ${colour.greyL1};
    }
  }
`