import { configureStore } from '@reduxjs/toolkit';
import { throttle } from 'lodash';
import createReducer from './rootReducer';

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const middlewares = [];

// if (process.env.NODE_ENV === 'development') {
//   const { logger } = require('redux-logger');
//
//   middlewares.push(logger);
// }

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('_cp_state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('_cp_state', serializedState);
  } catch {
    // ignore write errors
  }
};

const store = configureStore({
  reducer: createReducer(),
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        'dialog/openDialog',
        'dialog/closeDialog',
        'message/showMessage',
        'message/hideMessage'
      ]
    }
  }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development'
});

store.subscribe(throttle(() => {
  saveState({
    sessions: store.getState().sessions
  });
}, 1000));

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

export default store;
