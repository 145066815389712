import AppContext from './AppContext';
import {Provider} from 'react-redux';
import store from './store/store';
import DynamicRouter from './services/dynamicRouter/DynamicRouter';
import AppRouter from './AppRouter';
import {BrowserRouter} from 'react-router-dom';
import './td-icons.css';
import '../assets/sass/dev/style.scss';
import ReactGA from 'react-ga';
import {SWRConfig} from 'swr'
import React from "react";
import RIDialog from "./@rawideas/core/RIDialog";
import {defaultFetcher} from "./api/swrFetcher";
import DesktopOnlyNotification from "./components/DesktopOnlyNotification/DesktopOnlyNotification";

ReactGA.initialize(process.env.REACT_APP_GTAG);

function App() {

    return (<>
        <Provider store={store}>
            <AppContext.Provider value={{}}>
                <BrowserRouter>
                    <DynamicRouter>
                        <SWRConfig value={{
                            fetcher: defaultFetcher,
                            refreshInterval: 0,
                            revalidateOnFocus: false
                        }}>
                            <RIDialog/>
                            <DesktopOnlyNotification />
                            <AppRouter/>
                        </SWRConfig>
                    </DynamicRouter>
                </BrowserRouter>
            </AppContext.Provider>
        </Provider>
    </>);
}

export default App;
