import React from 'react';
import loaderSky from '../assets/svg/loader-sky.svg';
import loaderEarth from '../assets/svg/loader-earth.svg';
import loaderGreen from '../assets/svg/loader-green.svg';
import {useLocation} from "react-router-dom";

function Loading({color}) {
	const location = useLocation();

	const resolveColor = () => {
		if(location.pathname.startsWith("/nomination") || location.pathname.startsWith("/enterprise-awards")){
			return "sky";
		}
		if(location.pathname.startsWith("/telstra-staff") || location.pathname.startsWith("/admin")){
			return "green";
		}
		return color;
	}

	const getLogo = () => {
		if(resolveColor() === "green") {
			return loaderGreen;
		}
		if(resolveColor() === "sky") {
			return loaderSky;
		}
		return loaderEarth;
	}
	return <div className={`pageLoader ${resolveColor()}`}>
		<img alt="Loading..." src={getLogo()}/>
		Loading
	</div>;
}

Loading.defaultProps = {
	color: "earth"
}
export default Loading;