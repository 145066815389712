import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import apiService from '../../api/apiService';

export const getAccreditationPartnerLevels = createAsyncThunk('accreditation/partnerLevels/get', async () => {
	const response = await apiService.accreditationsGetPartnerLevels();
	return response.data;
});

const accreditationPartnerLevelsAdapter = createEntityAdapter({
	selectId: (accreditationPartnerLevels) => accreditationPartnerLevels.id
});

export const {
	selectAll: selectAccreditationPartnerLevels,
	selectById: selectAccreditationPartnerLevelsById
} = accreditationPartnerLevelsAdapter.getSelectors(state =>  state.accreditationPartnerLevels);

const accreditationPartnerLevelsSlice = createSlice({
	name: 'accreditation/partnerLevels',
	initialState: accreditationPartnerLevelsAdapter.getInitialState(),
	reducers: {},
	extraReducers: {
		[getAccreditationPartnerLevels.fulfilled]: (state, action) => {
			accreditationPartnerLevelsAdapter.setAll(state, action.payload);
		}
	}
});

export default accreditationPartnerLevelsSlice.reducer;
