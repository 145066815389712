import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectSitemap } from '../../store/sitemap/sitemapSlice';
import { setNavigationItems } from '../../store/navigation/navigationSlice';
import NavigationModel from '../../models/navigationModel';
import jwtService from '../jwtService/jwtService';
import jwtDecode from 'jwt-decode';
import { logoutUser, setUserData } from '../../auth/store/userSlice';
import { useHistory } from 'react-router-dom';
import { isArray } from 'lodash';

export const noLogonRedirectNavigation = [
	'/login',
	'/register',
	'/register/pending',
	'/register/success',
	'/lostpassword',
	'/changepassword',
	'/member/confirm',
	// '/nomination-form'
];

function DynamicRouter(props) {
	const sitemap = useSelector(selectSitemap);
	const dispatch = useDispatch();

	const history = useHistory();

	useEffect(() => {
		const handleAuth = async () => {
			new Promise(resolve => {
				jwtService.on('onAutoLogin', () => {
					const access_token = jwtService.getAccessToken();
					const decoded = jwtDecode(access_token);
					dispatch(setUserData({
						authenticated: true,
						redirectUrl: '/',
						login: {
							errorCode: null,
							errorText: null,
						},
						userData: {
							email: decoded.email,
							firstName: decoded.firstName,
							lastName: decoded.lastName,
							id: decoded.id,
							name: decoded.name,
							company: decoded.company,
							privileged: decoded.privileged,
							type: decoded.type,
							groups: decoded.groups,
						}
					})).then(() => {
						// todo: redirect here to the place we were at.
						if (sitemap.length === 0) {
							//dispatch(getSitemap());
						}
						resolve();
					})
				});

				jwtService.on('onAutoLogout', message => {
					dispatch(logoutUser()).then(() => {
						if (!noLogonRedirectNavigation.find((route) => history.location.pathname.startsWith(route))) {
							history.push("/login?referrer=" + history.location.pathname);
						}
					});
					resolve();
				});

				jwtService.on('onNoAccessToken', () => {
					if (!noLogonRedirectNavigation.find((route) => history.location.pathname.startsWith(route))) {
						history.push("/login?referrer=" + history.location.pathname);
					}
					resolve();
				});

				jwtService.init();

				return Promise.resolve();
			});
		};
		handleAuth();
		//eslint-disable-next-line
	}, []);

	const addItem = (navItems, item) => {
		navItems.push({
			id: item.id,
			data: item.data,
			children: item.children,
			component: item.type,
			permissionrequired: NavigationModel.PermissionLevel.STAFF,
			path: '/' + item.url
		});
		if (isArray(item.children)) {
			item.children.forEach(subitem => {
				addItem(navItems, subitem);
			})
		}
	};

	useEffect(() => {
		let navItems = [];
		sitemap.forEach(item => {
			addItem(navItems,item);
		});
		dispatch(setNavigationItems(navItems))
			.then(() => {
				//console.log('nav items have been set');
			});
		//eslint-disable-next-line
	}, [sitemap]);

	return props.children;
}

export default DynamicRouter;
