import React, {useEffect, useLayoutEffect} from 'react';
import { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import SecureRoute from './SecureRoute';
import CP from './layouts/cp/CP';
import Loading from './Loading';
import {useHistory, useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as ReactGA from 'react-ga';

const Login = React.lazy(() => import('./main/login/Login'));
const Register = React.lazy(() => import('./main/register/Register'));
const RegisterSuccess = React.lazy(() => import('./main/register/RegisterSuccess'));
const RegisterPending = React.lazy(() => import('./main/register/RegisterPending'));
const Logout = React.lazy(() => import('./main/logout/Logout'));
const LostPassword = React.lazy(() => import('./main/lostpassword/LostPassword'));
const ChangePasswordWithToken = React.lazy(() => import('./main/lostpassword/ChangePasswordWithToken'));
const NotFound = React.lazy(() => import('./components/NotFound'));
const CMSPage = React.lazy(() => import('./components/CMSPage'));
const BadgeGeneratorPage = React.lazy(() => import('./components/pages/BadgeGeneratorPage'));
const AwardBadgeGeneratorPage = React.lazy(() => import('./components/pages/AwardBadgeGeneratorPage'));
const SubmitRequestPage = React.lazy(() => import('./components/pages/ContactUsPage'));
const SearchPartnerPage = React.lazy(() => import('./components/pages/Admin/SearchPartnerPage'));
const ReportsPage = React.lazy(() => import('./components/pages/Admin/ReportsPage'));
const StaffReportsPage = React.lazy(() => import('./components/pages/Staff/StaffReportsPage'));
const NominationGeneralPage = React.lazy(() => import('./components/pages/nomination/NominationGeneralPage/NominationGeneralPage'));
const NominationQuestionPage = React.lazy(() => import('./components/pages/nomination/NominationQuestionPage/NominationQuestionPage'));
const NominationQuestionViewPage = React.lazy(() => import('./components/pages/nomination/NominationQuestionViewPage/NominationQuestionViewPage'));
const InviteReviewersPage = React.lazy(() => import('./components/pages/InviteReviewersPage/InviteReviewersPage'));
const NominationAwardSuccess = React.lazy(() => import('./components/pages/nomination/NominationAwardSuccess/NominationAwardSuccess'));
const NominationFormPartnerDetailsPage = React.lazy(() => import('./components/pages/nomination/NominationFormPartnerDetailsPage/NominationFormPartnerDetailsPage'));
const NominationFormAwardSelectionPage = React.lazy(() => import('./components/pages/nomination/NominationFormAwardSelectionPage/NominationFormAwardSelectionPage'));
const NominationSubmissionOverviewPage = React.lazy(() => import('./components/pages/nomination/NominationSubmissionOverviewPage/NominationSubmissionOverviewPage'));
const NominationSubmissionQuestionPage = React.lazy(() => import('./components/pages/nomination/NominationSubmissionQuestionPage/NominationSubmissionQuestionPage'));
const NominationsSubmittedReportPage = React.lazy(() => import('./components/pages/managerReports/NominationsByPartnerReport/NominationsSubmittedReportPage'));
const NominationsCommencedReportPage = React.lazy(() => import('./components/pages/managerReports/NominationsByPartnerReport/NominationsCommencedReportPage'));
const NominationsReceivedReport =  React.lazy(() => import('./components/pages/managerReports/NominationsReceivedReport/NominationsReceivedReport'));
const ConfirmMemberRedirectPage = React.lazy(() => import('./components/ConfirmMemberRedirectPage'));
const EnterpriseAwardsFinalistsPage = React.lazy(() => import('./components/pages/awards/EnterpriseAwardsFinalistsPage'));
const EnterpriseAwardsWinnersPage = React.lazy(() => import('./components/pages/awards/EnterpriseAwardsWinnersPage'));
const DesktopOnlyPage = React.lazy(() => import('./components/DesktopOnlyPage/DesktopOnlyPage'));

function AppRouter(props) {

	const {userData} = useSelector(({auth}) => auth.user);
	const history = useHistory();
	const location = useLocation();

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		ReactGA.ga('set', {
			dimension1: userData.type,
			dimension2: userData.privileged ? 'admin' : 'user'
		});
		history.listen((location) => {
			if (userData.email) {
				ReactGA.pageview(location.pathname + location.search);
			}
		});
		//eslint-disable-next-line
	}, [userData]);

	return (
		<>
			<CP>
				<Suspense fallback={<Loading />}>
					<Switch>
						<SecureRoute exact path="/" component={CMSPage} key="homepage"/>
						<Route exact path="/mobile" component={DesktopOnlyPage} key="mobile" />
						<Route exact path="/login" component={Login} key="login" />
						<Route exact path="/register" component={Register} key="register" />
						<Route exact path="/register/success" component={RegisterSuccess} key="register-success" />
						<Route exact path="/register/pending" component={RegisterPending} key="register-pending" />
						<Route exact path="/logout" component={Logout} key="logout" />
						<Route exact path="/lostpassword" component={LostPassword} key="lostpassword" />
						<Route exact path="/lostpassword/:id/:token" component={ChangePasswordWithToken} key="lostpassword-2" />
						<Route exact path="/changepassword/:id/:token" component={ChangePasswordWithToken} key="changepassword" />
						<Route exact path="/member/confirm/:id" component={ConfirmMemberRedirectPage} key="member-confirm" />
						<SecureRoute exact path="/asset-downloads/accreditation-badge" component={BadgeGeneratorPage} key="create-your-badge" />
						<SecureRoute exact path="/asset-downloads/partner-awards" component={AwardBadgeGeneratorPage} key="partner-awards" />
						<SecureRoute exact path="/brand-use-terms" component={CMSPage} key="brand-use-terms" />
						<SecureRoute exact path="/submit-a-request" component={SubmitRequestPage} key="submit-a-request" />
						<SecureRoute exact path="/nomination-invite-reviewers" component={InviteReviewersPage} key="invite-reviewers" />
						<SecureRoute exact path="/nomination-enterprise-awards" component={CMSPage} item={{}} key="nomination-enterprise-awards" componentProps={{showButton: true}} />
						<SecureRoute exact path="/how-to-nominate" component={CMSPage} key="how-to-nominate"  />
						{/* Nominations closed page start */}
						<SecureRoute exact path="/nominate" component={CMSPage} key="nominate" />
						{/* Nominations closed page end */}
						{/* Open Nominations start */}
						<SecureRoute exact path="/nomination-form-partner-details" component={NominationFormPartnerDetailsPage} item={{}} key="nomination-form-partner-details" />
						<SecureRoute exact path="/nomination-form-general" component={NominationGeneralPage} key="nomination-form-general" />
						<SecureRoute exact path="/nomination-form-award-selection" component={NominationFormAwardSelectionPage} item={{}} key="nomination-form-award-selection" />
						<SecureRoute exact path="/nomination-form-award-selection/:awardId" component={NominationFormAwardSelectionPage} item={{}} key="nomination-form-award-selection-id" />
						<SecureRoute exact path="/nomination-form/:nominationId" component={NominationQuestionPage} key="nomination-form" />
						<SecureRoute exact path="/nomination-form/:nominationId/view" component={NominationQuestionViewPage} key="nomination-form-view" />
						<SecureRoute exact path="/nomination-form-success" component={NominationAwardSuccess} key="nomination-form-success" />
						{/* Open Nominations end */}
						<SecureRoute exact path="/admin/search-partner" component={SearchPartnerPage} key="admin-search-partner" />
						<SecureRoute exact path="/admin/reports" component={ReportsPage} key="admin-reports" />
						<SecureRoute exact path="/telstra-staff/nomination-submissions-overview" component={NominationSubmissionOverviewPage} item={{}} key="admin-nomination-submissions-overview" />
						<SecureRoute exact path="/telstra-staff/nomination-submissions-question/:nominationId/:partnerId/:fromPage" component={NominationSubmissionQuestionPage} item={{}} key="admin-nomination-submissions-question" />
						<SecureRoute exact path="/telstra-staff/nomination-submissions-question/:nominationId/:partnerId/:fromPage/view" component={NominationSubmissionQuestionPage} item={{}} key="admin-nomination-submissions-question" />
						<SecureRoute exact path="/telstra-staff/nomination-report-commenced" component={NominationsCommencedReportPage} key="admin-nomination-report-commenced" />
						<SecureRoute exact path="/telstra-staff/nomination-report-submitted" component={NominationsSubmittedReportPage} key="admin-nomination-report-submitted" />
						<SecureRoute exact path="/telstra-staff/nomination-report-received" component={NominationsReceivedReport} key="admin-nomination-report-received" />
						<SecureRoute exact path="/telstra-staff/reports" component={StaffReportsPage} key="telstra-admin-reports" />

						<SecureRoute exact path="/brand-identity/accreditation" component={CMSPage} key="brand-identity/accreditation" />
						<SecureRoute exact path="/brand-identity/awards" component={CMSPage} key="brand-identity/awards" />

						<SecureRoute exact path="/brand-guidelines/asset-types" component={CMSPage} key="brand-guidelines/asset-types" />
						<SecureRoute exact path="/brand-guidelines/asset-colours" component={CMSPage} key="brand-guidelines/asset-colours" />
						<SecureRoute exact path="/brand-guidelines/placing-badges" component={CMSPage} key="brand-guidelines/placing-badges" />
						<SecureRoute exact path="/brand-guidelines/digital-assets" component={CMSPage} key="brand-guidelines/digital-assets" />
						<SecureRoute exact path="/brand-guidelines/print-assets" component={CMSPage} key="brand-guidelines/print-assets" />

						<SecureRoute exact path="/how-to-create/accreditation-badges" component={CMSPage} key="how-to-create/accreditation-badges" />
						<SecureRoute exact path="/how-to-create/award-badges" component={CMSPage} key="how-to-create/award-badges" />

						<SecureRoute exact path="/asset-downloads/certificates" component={CMSPage} key="asset-downloads/certificates" />
						<SecureRoute exact path="/asset-downloads/certification_badges" component={CMSPage} key="asset-downloads/certification_badges" />
						<SecureRoute exact path="/asset-downloads/logos" component={CMSPage} key="asset-downloads/logos" />

						<SecureRoute exact path="/enterprise-awards" component={CMSPage} key="enterprise-awards" />

						<SecureRoute exact path="/enterprise-awards/finalists" component={EnterpriseAwardsFinalistsPage} key="enterprise-awards/finalists" />
						<SecureRoute exact path="/enterprise-awards/winners" component={EnterpriseAwardsWinnersPage} key="enterprise-awards/winners" />
						<SecureRoute exact path="/enterprise-awards/overview" component={CMSPage} key="enterprise-awards/overview" />
						{/*<SecureRoute exact path="/enterprise-awards/domain/data-and-ip" component={CMSPage} key="enterprise-awards/domain/data-and-ip" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/domain/uc" component={CMSPage} key="enterprise-awards/domain/uc" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/domain/cloud" component={CMSPage} key="enterprise-awards/domain/cloud" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/domain/iot" component={CMSPage} key="enterprise-awards/domain/iot" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/domain/services" component={CMSPage} key="enterprise-awards/domain/services" />*/}
						<SecureRoute exact path="/enterprise-awards/domain/mobility" component={CMSPage} key="enterprise-awards/domain/mobility" />
						<SecureRoute exact path="/enterprise-awards/domain/data-and-connectivity" component={CMSPage} key="enterprise-awards/domain/data-and-connectivity" />
						<SecureRoute exact path="/enterprise-awards/domain/nas" component={CMSPage} key="enterprise-awards/domain/nas" />

						<SecureRoute exact path="/enterprise-awards/alliance/microsoft" component={CMSPage} key="enterprise-awards/alliance/microsoft" />
						<SecureRoute exact path="/enterprise-awards/alliance/cisco" component={CMSPage} key="enterprise-awards/alliance/cisco" />
						{/*<SecureRoute exact path="/enterprise-awards/alliance/ms-infrastructure" component={CMSPage} key="enterprise-awards/alliance/ms-infrastructure" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/alliance/ms-modern-wp" component={CMSPage} key="enterprise-awards/alliance/ms-modern-wp" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/alliance/cisco-infrastructure" component={CMSPage} key="enterprise-awards/alliance/cisco-infrastructure" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/alliance/cisco-collaboration" component={CMSPage} key="enterprise-awards/alliance/cisco-collaboration" />*/}

						{/*<SecureRoute exact path="/enterprise-awards/excellence-and-values/customer" component={CMSPage} key="enterprise-awards/excellence-and-values/customer" />*/}
						<SecureRoute exact path="/enterprise-awards/excellence-and-values/channel" component={CMSPage} key="enterprise-awards/excellence-and-values/channel" />
						<SecureRoute exact path="/enterprise-awards/excellence-and-values/peter-carr-customer-excellence" component={CMSPage} key="enterprise-awards/excellence-and-values/peter-carr-customer-excellence" />
						{/*<SecureRoute exact path="/enterprise-awards/excellence-and-values/innovation" component={CMSPage} key="enterprise-awards/excellence-and-values/innovation" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/excellence-and-values/customer-excellence-tn" component={CMSPage} key="enterprise-awards/excellence-and-values/customer-excellence-tn" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/excellence-and-values/channel-champion-tn" component={CMSPage} key="enterprise-awards/excellence-and-values/channel-champion-tn" />*/}

						<SecureRoute exact path="/enterprise-awards/enterprise/distribution" component={CMSPage} key="enterprise-awards/enterprise-awards/distribution" />
						{/*<SecureRoute exact path="/enterprise-awards/enterprise/distributor" component={CMSPage} key="enterprise-awards/enterprise-awards/distributor" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/enterprise/purple-partner" component={CMSPage} key="enterprise-awards/enterprise-awards/purple-partner" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/enterprise/australia-deal" component={CMSPage} key="enterprise-awards/enterprise-awards/australia-deal" />*/}
						<SecureRoute exact path="/enterprise-awards/enterprise/enterprise-partner" component={CMSPage} key="enterprise-awards/enterprise-awards/enterprise-partner" />
						<SecureRoute exact path="/enterprise-awards/enterprise/business-partner" component={CMSPage} key="enterprise-awards/enterprise-awards/business-partner" />

						{/*<SecureRoute exact path="/enterprise-awards/telstra-nominated/emerging-partner" component={CMSPage} key="enterprise-awards/telstra-nominated/emerging-partner" />*/}
						{/*<SecureRoute exact path="/enterprise-awards/telstra-nominated/service-recognition" component={CMSPage} key="enterprise-awards/telstra-nominated/service-recognition" />*/}
						<SecureRoute exact path="/enterprise-awards/telstra-nominated/hall-of-fame" component={CMSPage} key="enterprise-awards/telstra-nominated/hall-of-fame" />

						<SecureRoute exact path="/enterprise-awards/state-awards/nsw-and-act" component={CMSPage} key="enterprise-awards/state-awards/nsw-and-act" />
						{/*<SecureRoute exact path="/enterprise-awards/state-awards/nsw" component={CMSPage} key="enterprise-awards/state-awards/nsw" />*/}
						<SecureRoute exact path="/enterprise-awards/state-awards/vic-and-tas" component={CMSPage} key="enterprise-awards/state-awards/vic-and-tas" />
						{/*<SecureRoute exact path="/enterprise-awards/state-awards/vic" component={CMSPage} key="enterprise-awards/state-awards/vic" />*/}
						<SecureRoute exact path="/enterprise-awards/state-awards/qld" component={CMSPage} key="enterprise-awards/state-awards/qld" />
						<SecureRoute exact path="/enterprise-awards/state-awards/wa" component={CMSPage} key="enterprise-awards/state-awards/wa" />
						{/*<SecureRoute exact path="/enterprise-awards/state-awards/sa" component={CMSPage} key="enterprise-awards/state-awards/sa" />*/}
						<SecureRoute exact path="/enterprise-awards/state-awards/sa-and-nt" component={CMSPage} key="enterprise-awards/state-awards/sa-and-nt" />

						<SecureRoute exact path="/enterprise-awards/judging-criteria" component={CMSPage} key="enterprise-awards/judging-criteria" />

						<Route component={NotFound} />
					</Switch>
				</Suspense>
			</CP>
		</>
	);
}

export default AppRouter;
