import axios from 'axios';
import config from './apiServiceConfig';
import RIUtils from '../@rawideas/utils';

class ApiService extends RIUtils.EventEmitter {

	static pageCache = [];

	userLogin = (email, password) => {
		return axios.post(`${config.url}/auth/token`, {
			username: email,
			password
		});
	};

	passwordReset = (email) => {
		return axios.post(`${config.url}/api/auth/passwordreset`, {
			email: email
		});
	}
	register = (data) => {
		return axios.post(`${config.url}/api/auth/user`, data);
	}

	confirmMember = (id) => {
		return axios.get(`${config.url}/member/confirm/${id}`);
	}

	changePassword = (password, confirmPassword, id, token) => {
		return axios.post(`${config.url}/api/auth/changepassword`, {
			password: password,
			confirmPassword: confirmPassword,
			id: id,
			token: token
		});
	}

	getSitemap = () => {
		return axios.get(`${config.url}/api/sitemap`);
	};

	logoutUser = () => {
		return axios.get(`${config.url}/api/logout/now`);
	};

	postSubmitForm = (data) => {
		return axios.post(`${config.url}/api/contactus/submit`, data);
	};

	// Accreditation calls ( ie: create-your-badge page )
	accreditationsGetPartnerAwards = () => {
		return axios.get(`${config.url}/api/generator/partnerawards`);
	};
	accreditationsGetPartnerLevels = () => {
		return axios.get(`${config.url}/api/generator/partnerlevel`);
	};
	accreditationsGetOverallLevels = () => {
		return axios.get(`${config.url}/api/generator/overallaccreditationlevels`);
	};

	accreditationsPostDisplayBadge = (data) => {
		return axios.post(`${config.url}/api/generator/displaybadge`, data);
	};
	accreditationsPostDownloadBadge = (data) => {
		return axios.post(`${config.url}/api/generator/download`, data);
	};

	// Partner Awards calls ( ie: partner-awards page )
	awardsGetPartnerAwards = () => {
		return axios.get(`${config.url}/api/awardgenerator/partnerawards`);
	};
	awardsPostDisplayBadge = (data) => {
		return axios.post(`${config.url}/api/awardgenerator/displaybadge`, data);
	};
	awardsPostDownloadBadge = (data) => {
		return axios.post(`${config.url}/api/awardgenerator/download`, data);
	};

	getCMSPage = async (id) => {
		const item = ApiService.pageCache.find(item => item.id === id);
		if (item) {
			return item;
		} else {
			await axios.get(`${config.url}/api/page/${id}`)
				.then((res) => {
					ApiService.pageCache.push({
						id: id,
						...res.data
					});
				});
			return ApiService.pageCache.find(item => item.id === id);
		}

	};

	//Admin
	searchPartner = (term) => {
		return axios.get(`${config.url}/api/admin/partner?q=${term}`);
	};
	searchPartnerById = (id) => {
		return axios.get(`${config.url}/api/admin/partner/${id}`);
	};
	getStatusReport = () => {
		return axios.get(`${config.url}/api/admin/channelpartnersreport`);
	};
	genBadgeUsageReport = (reportDateFrom, reportDateTo) => {
		return axios.get(`${config.url}/api/admin/badgeusagereport?from=${reportDateFrom}&to=${reportDateTo}`);
	};

	// Admin Report
	getNominationsSummaryReport = (year) => {
		return axios.get(`${config.url}/api/admin/report/nominationsummary?year=${year}`, {responseType: "blob"});
	};
	getNominationsByPartnerReport = (year) => {
		return axios.get(`${config.url}/api/admin/report/nominationsbypartner?year=${year}`, {responseType: "blob"});
	};
	getNominationsByAwardReport = (year) => {
		return axios.get(`${config.url}/api/admin/report/nominationsbyaward?year=${year}`, {responseType: "blob"});
	};

	// Partner APIs
	addReviewers = (data) => {
		return axios.put(`${config.url}/api/partner/invitees`, data);
	};

	// Nomination APIs
	createNomination = (awardId) => {
		return axios.post(`${config.url}/api/nomination`, {id: awardId});
	};
	deleteNomination = (id) => {
		return axios.delete(`${config.url}/api/nomination/${id}`);
	};
	// startNomination = (id) => {
	// 	return axios.put(`${config.url}/api/nomination/${id}/start`);
	// };
	restartNomination = (id) => {
		return axios.put(`${config.url}/api/nomination/${id}/restart`);
	};
	submitNomination = (id, data = null) => {
		return axios.put(`${config.url}/api/nomination/${id}/submit`, data);
	};
	getAllNominations = () => {
		return axios.get(`${config.url}/api/nomination`,{
			headers: {
				"X-IncludeRelated": `true`
			}
		});
	};
	getNomination = (id) => {
		return axios.get(`${config.url}/api/nomination/${id}`);
	};
	sendNominationApproval = (id) => {
		return axios.put(`${config.url}/api/nomination/${id}/clientapproval`);
	};


	//Awards APIs
	getAwardGroups = () => {
		return axios.get(`${config.url}/api/awardgroup`);
	};
	getAwardGroup = (id) => {
		return axios.get(`${config.url}/api/awardgroup/${id}`);
	};
	getAwards = () => {
		return axios.get(`${config.url}/api/awards`);
	};
	getAward = (id) => {
		return axios.get(`${config.url}/api/awards/${id}`);
	};

	//Answer APIs
	saveAnswers = (nominationId, data) => {
		return axios.put(`${config.url}/api/answer/${nominationId}`, data);
	};

	// Partner Manager Nomination APIs
	getPartnerDetailsById = (partnerId) => {
		return axios.get(`${config.url}/api/partnermanager/partner/${partnerId}`);
	};
	createPartnerNominationByManager = (partnerId, awardId) => {
		return axios.post(`${config.url}/api/partnermanager/nomination/${partnerId}`, {id: awardId});
	};
	deletePartnerNominationByManager = (partnerId, id) => {
		return axios.delete(`${config.url}/api/partnermanager/nomination/${partnerId}/${id}`);
	};
	startPartnerNominationByManager = (partnerId, id) => {
		return axios.put(`${config.url}/api/partnermanager/nomination/${partnerId}/${id}/start`);
	};
	restartPartnerNominationByManager = (partnerId, id) => {
		return axios.put(`${config.url}/api/partnermanager/nomination/${partnerId}/${id}/restart`);
	};
	submitPartnerNominationByManager = (partnerId, id) => {
		return axios.put(`${config.url}/api/partnermanager/nomination/${partnerId}/${id}/submit`);
	};
	getAnswersForPartnerNominationByManager = (partnerId, id) => {
		return axios.get(`${config.url}/api/partnermanager/answer/${partnerId}/${id}`);
	};
	saveManagerNominationAnswers = (partnerId, nominationId, data) => {
		return axios.put(`${config.url}/api/partnermanager/answer/${partnerId}/${nominationId}`, data);
	};

	// Partner Manager Admin APIs
	managerSearchPartner = (term) => {
		return axios.get(`${config.url}/api/partnermanager/partners?contains[Name]=${term}&contains[DealerCode]=${term}`);
	};
	managerSearchPartnerById = (id) => {
		return axios.get(`${config.url}/api/partnermanager/partner/${id}`);
	};

}

const instance = new ApiService();

export default instance;
