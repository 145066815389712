import { createSlice } from '@reduxjs/toolkit';

const initialState = { selectedPartner: null}

const managerNominationSlice = createSlice({
    name: 'nomination/managerNomination',
    initialState,
    reducers: {
        setSelectedPartner: (state, action) => {
            state.selectedPartner = action.payload
        }
    },
});

export const { setSelectedPartner } = managerNominationSlice.actions;
export default managerNominationSlice.reducer;