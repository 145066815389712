import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import apiService from '../../api/apiService';

export const getAwardsPartnerAwards = createAsyncThunk('awards/partnerAwards', async () => {
	const response = await apiService.awardsGetPartnerAwards();
	return response.data;
});

const awardsPartnerAwardsAdapter = createEntityAdapter({
	selectId: (awardsPartnerAwards) => awardsPartnerAwards.id
});

export const { selectAll: selectAwardsPartnerAwards, selectById: selectAwardsPartnerAwardsById }
	= awardsPartnerAwardsAdapter.getSelectors(state => state.awardsPartnerAwards);

const awardsPartnerAwardsSlice = createSlice({
	name: 'awards/partnerAwards',
	initialState: awardsPartnerAwardsAdapter.getInitialState(),
	reducers: {},
	extraReducers: {
		[getAwardsPartnerAwards.fulfilled]: (state, action) => {
			awardsPartnerAwardsAdapter.setAll(state, action.payload);
		}
	}
});

export default awardsPartnerAwardsSlice.reducer;
