import RIUtils from '../../@rawideas/utils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import ApiService from '../../api';

/* eslint-disable camelcase */

class JwtService extends RIUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (typeof err.response !== 'undefined' && err.response.status === 401) {
						// hack to check if we should emit an event, time contraints mean this is here and not fixed somewhere else.
						// checks the call was not logout and auto logs the person out.
						if (err.response.config.url.indexOf('logout') === -1 && err.response.config.url.indexOf('auth/token') === -1) {
							this.emit('onAutoLogout', 'Invalid access_token');
							this.setSession(null);
						}
						resolve();
					} else {
						reject(err);
					}
					//throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();
		if (!access_token) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

/*	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/register', data).then(response => {
				if (response.data.user) {
					this.setSession(response.data.access_token);
					resolve(response.data.user);
				} else {
					reject(response.data.error);
				}
			});
		});
	};*/

	signInWithEmailAndPassword = (email, password) => {
		return new Promise((resolve, reject) => {
			ApiService.userLogin(email, password).then(response => {

				if (response.data.access_token) {
					this.setSession(response.data.access_token);
					resolve(response);
				} else {
					reject(response);
				}
			});
		});
	};

/*	signInWithToken = async () => {
		return new Promise(async (resolve, reject) => {
			// grab the token from storage.
			const access_token = this.getAccessToken();
			const decoded = jwtDecode(access_token);
			dispatch(setUser({

			}))
			const data = await response.data;
			if (data) {
				resolve(data);
			} else {
				reject();
			}
		});
	};*/

	updateUserData = user => {
		return axios.put('/auth/user', {
			user
		});
	};

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('__GaX6Oopee1paT9ae', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('__GaX6Oopee1paT9ae');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	logout = () => {
		this.setSession(null);
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('__GaX6Oopee1paT9ae');
	};
}

const instance = new JwtService();

export default instance;
