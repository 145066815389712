import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import apiService from '../../api/apiService';

export const getSitemap = createAsyncThunk('sitemap/sitemap/get', async () => {
	const response = await apiService.getSitemap();
	return response.data;
});

const sitemapAdapter = createEntityAdapter({
	selectId: (sitemap) => sitemap.id
});

export const { selectAll: selectSitemap, selectById: selectSitemapById }
	= sitemapAdapter.getSelectors(state => state.sitemap);

const sitemapSlice = createSlice({
	name: 'sitemap/sitemap',
	initialState: sitemapAdapter.getInitialState(),
	reducers: {},
	extraReducers: {
		[getSitemap.fulfilled]: (state, action) => {
			sitemapAdapter.setAll(state, action.payload);
		}
	}
});

export default sitemapSlice.reducer;
