export const colour = {
    blueL1: '#001E82',
    blueL2: '#0064D2',
    blueL3: '#0099F8',
    blueL4: '#B2E0FD',
    white: '#FFFFFF',
    black: '#000000',
    redError: '#D0021B',
    redErrorHover: '#AC0442',
    redDanger: '#FDF7F8',
    greyL1: '#414141',
    greyL2: '#787878',
    greyL3: '#aaaaaa',
    greyL4: '#d2d2d2',
    greyL5: '#e6e6e6',
    greyL6: '#F9F9F9',
    magentaL1: '#570066',
    magentaL2: '#A0148C',
    magentaL3: '#FF2896',
    magentaL4: '#FFBEDF',
    greenL1: '#074F30',
    greenL2: '#008A00',
    greenL3: '#00ac00',
    greenL4: '#B2E6B2',
    sky100: '#131A35',
    sky80: '#1A2E61',
    sky60: '#0D54FF',
    sky40: '#6CCBFE',
    sky20: '#D8F0FE',
    sand60: '#D4CDC3',
    sand40: '#F5EDE2',
    sand20: '#FEF9EF',
    earth80: '#F44E1A',
    earth60: '#F96449',
    earth40: '#FFAE87',
    earth20: '#FFDDD1',
    grey100: '#050505',
    grey80: '#505050',
    grey60: '#828282',
    grey40: '#A0A0A0',
    grey30: '#C8C8C8',
    grey20: '#E3E3E3',
    greyFooter: '#F4F4F4',
    green80: '#074F30',
    green60: '#008A00',
    green40: '#00ac00',
    green20: '#B2E6B2',
    magenta80: '#F44E1A',
    magenta60: '#F96449',
    magenta40: '#FFAE87',
    magenta20: '#FFDDD1',
    blue100: '#131A35',
    blue80: '#1A2E61',
    blue60: '#0D54FF',
    blue40: '#6CCBFE',
    blue20: '#D8F0FE',
}