import styled, {css} from "styled-components";
import {colour} from "../../cssVariables";
import arrowRight from '../../../assets/images/iconArrowRightWhite.png';
import arrowLeft from '../../../assets/images/iconArrowLeftWhite.png';

const getColor = (color, variant) => {
  if(color){
    return colour[`${color}${variant}`];
  }
  return colour[`sky${variant}`];
}

export const StyledButton = styled.button`
  background: ${props => getColor(props.color, "60")};
  border: none;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  padding: 0;
  transition: background 0.3s;
  border-radius: 10px;

  ${props => props.arrowLeft && css`
    text-align: right;
  `}
  
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  > span {
    color: ${colour.white};
    font-size: 18px;
    line-height: ${props => props.buttonHeight ? props.buttonHeight : '50px'};
    padding: 0 20px 0 20px;
    display: inline-block;
    width: auto;

    ${props => props.minWidth && css`
      min-width: ${props.minWidth};
      text-align: center;
    `}
  }

  > i {
    background: transparent url(${props => props.arrowLeft ? arrowLeft : arrowRight}) center center no-repeat;
    background-size: 28%;
    padding: 18px 10px;
    vertical-align: middle;
    width: 34px;
    display: inline-block;
  }

  &:hover {
    background-color: ${props => getColor(props.color, "80")};
  }

  &:disabled {
    background: ${props => getColor(props.color, "20")};
    > i, > span {
      color: ${colour.white};
      cursor: auto;
    }
  }

  ${props => props.variant === 'outlined' && css`
    background: white;
    border: 1px solid ${props => getColor(props.color, "60")};
    > span {
      color: ${props => getColor(props.color, "60")};
    }

    &:hover {
      background-color: white;
      border: 1px solid ${props => getColor(props.color, "80")};
      > span {
        color: ${props => getColor(props.color, "80")};
      }
    }
  `}
`