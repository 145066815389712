import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {closeDialog} from "../../../store/notification/notificationSlice";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import Button from "../../../components/Button/Button";
import {StyledDialog} from "./RIDialog.style";
import clsx from "clsx";

const RIDialog = () => {
    const dispatch = useDispatch();
    const {isOpen, message, action, isError, showHeader, headerText, onCloseCallback} = useSelector(({notification}) => notification);

    const handleClose = () => {
        if(onCloseCallback){
            onCloseCallback();
        }
        dispatch(closeDialog())
    }

    const handleAction = () => {
        action();
        dispatch(closeDialog())
    }

    return (<StyledDialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={clsx({'error': isError})}
    >
        <DialogTitle id="alert-dialog-title">{showHeader ? headerText ? headerText : isError ? "An error occurred" : "Warning" : ""}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        {action ? <DialogActions>
            <Button onClick={handleClose} label={"No"}/>
            <Button onClick={handleAction} autoFocus label={"Yes"}/>
        </DialogActions> : <DialogActions>
            <Button onClick={handleClose} label={"Ok"}/>
        </DialogActions>}
    </StyledDialog>);
}

export default RIDialog;
