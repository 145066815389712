import React from 'react';
import logo2023 from '../../../../assets/images/Telstra_Primary-logo_C_RGB.png';
import logoGreen from '../../../../assets/images/logoGreen.png';
import { useSelector } from 'react-redux';
import {NavLink, useLocation} from 'react-router-dom';

function Header(props) {
	//const toolbarTheme = useSelector(selectToolbarTheme);

	// const classes = useStyles(props);
	const location = useLocation();
  const user = useSelector(({ auth }) => auth.user);

  const isNomination = () => location.pathname.startsWith("/nomination") || location.pathname.startsWith("/enterprise-awards") || location.pathname.startsWith("/how-to-nominate") || location.pathname.startsWith("/nominate");
  const isAdmin = () => location.pathname.startsWith("/admin") || location.pathname.startsWith("/telstra-staff");

  const resolveColor = () => {
  	if(isNomination()){
  		return "sky";
	}
  	if(isAdmin()){
  		return "green";
	}
  	return "earth";
  }

  const resolveLogo = () => {
    // if(isAdmin()){
	//     return logoGreen;
    // }
  	return logo2023;
  }

	return (
  <header className={`headerMain ${resolveColor()} noUser`}>
    <div className={`linearGradient ${resolveColor()}`} />

    <div className="headerMain-wrapper">
      <i className="fa fa-bars" />
		<NavLink className="headerMain_container containerLogos" to="/">
			  <img className={`containerLogos_logo containerLogos_logo-${resolveColor()}`}
			  src={resolveLogo()}
			  alt="Telstra logo"
			/>
			<span className="containerLogos_title">Telstra Channel Partner Hub</span>

		</NavLink>

      <i className="fa-times td-icon icon-ui-cross" />
      {user.authenticated &&
      <div className="headerMain_container toolBox">
        <div className="toolBox_item toolBox_item-logOut">
			<NavLink to="/logout">Logout</NavLink>
        </div>
        <div className={`toolBox_item toolBox_item-needHelp ${resolveColor()}`} tabIndex="-1">
			<NavLink className="needHelpLink" to="/submit-a-request">
				<span className="td-icon td-icon-s icon-email-support"/>
				<span className="text">
				  Need help? <b>Ask Partner Sales</b>
				</span>
			</NavLink>
        </div>
      </div>
      }
    </div>

  </header>
/*		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx(classes.root, 'flex relative z-10')}
				color="default"
				style={{ backgroundColor: toolbarTheme.palette.background.paper }}
				elevation={2}
			>
				<Toolbar className="p-0 min-h-48 md:min-h-64">
					<div className="flex flex-1">&nbsp;</div>
					<div className="flex items-center px-16">
						{/!*<FuseSearch />*!/}
						<QuickPanelToggleButton />

						<UserMenu />
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>*/
	);
}

export default React.memo(Header);
