import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const navigationAdapter = createEntityAdapter({
	selectId: (navigation) => navigation.id
});

export const { selectAll: selectNavigation, selectById: selectNavigationById }
	= navigationAdapter.getSelectors(state => state.navigation);

export const setNavigationItems = items => async (dispatch, getState) => {
	dispatch(addManyNavigationItems(items));
};

const navigationSlice = createSlice({
	name: 'navigation/navigation',
	initialState: navigationAdapter.getInitialState(),
	reducers: {
		addManyNavigationItems: (state, action) => {
			navigationAdapter.setAll(state, action.payload);
		}
	},
	extraReducers: {}
});

export const { addManyNavigationItems } = navigationSlice.actions;

export default navigationSlice.reducer;
