import { createSlice } from '@reduxjs/toolkit';
import jwtService from '../../services/jwtService';
import apiService from '../../api/apiService';

const initialState = {
	authenticated: false,
	redirectUrl: '/',
	login: {
		errorCode: null,
		errorText: null,
	},
	userData: {
		email: null,
		firstName: null,
		lastName: null,
		id: 0,
		name: null,
		company: null,
		privileged: false,
		type: null,
		groups: [],
	}
};

export const submitPasswordReset = ({fieldEmailAddress}) => async dispatch => {
	return apiService.passwordReset(fieldEmailAddress);
}

export const submitChangePassword = ({password, confirmPassword, id, token}) => async dispatch => {
	return apiService.changePassword(password, confirmPassword, id, token);
}

export const submitLogin = ({fieldEmailAddress, fieldPassword }) => async dispatch => {
	return jwtService
		.signInWithEmailAndPassword(fieldEmailAddress, fieldPassword);
};

export const setUserData = user => async (dispatch, getState) => {
	dispatch(setUser(user));
};

export const logoutUser = () => async (dispatch, getState) => {
	await apiService.logoutUser();
	jwtService.logout();
	await dispatch(userLoggedOut());
	return null;
};

export const updateUserData = user => async (dispatch, getState) => {
	if (!user.role || user.role.length === 0) {
		// is guest
	}
};


const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => action.payload,
		userLoggedOut: (state, action) => initialState
	},
	extraReducers: {}
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
