import { combineReducers } from '@reduxjs/toolkit';
import auth from '../auth/store';
//import filters from './filters';
import sitemap from './sitemap/sitemapSlice';
import navigation from './navigation/navigationSlice';
import accreditationPartnerAwards from './accreditationPartnerAwards/accreditationPartnerAwardsSlice';
import accreditationPartnerLevels from './accreditationPartnerLevels/accreditationPartnerLevelsSlice';
import awardsPartnerAwards from './awardsPartnerAwards/awardsPartnerAwardsSlice';
import notification from './notification/notificationSlice';
import nomination from './nomination'

const createReducer = (asyncReducers) => combineReducers({
		auth,
		sitemap,
		navigation,
		accreditationPartnerAwards,
		accreditationPartnerLevels,
		awardsPartnerAwards,
		notification,
		nomination,
		...asyncReducers
	});

export default createReducer;
