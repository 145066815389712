import React from 'react';

const AwardBadgeGeneratorPage = React.lazy(() => import('../components/pages/AwardBadgeGeneratorPage'));
const BadgeGeneratorPage = React.lazy(() => import('../components/pages/BadgeGeneratorPage'));
const ContactUsPage = React.lazy(() => import('../components/pages/ContactUsPage'));

export default class NavigationModel {
	constructor({id, component, permissionrequired, path}) {
		this.id = id || 0;
		this.component = component || '';
		this.permissionrequired = permissionrequired || '';
		this.path = path || '';
	}

	getId() {
		return this.id;
	}

	getPath() {
		return this.path;
	}

	getComponent() {
		switch (this.component) {
			case "Telstra\\ChannelPartners\\Pages\\AwardBadgeGeneratorPage":
				return <AwardBadgeGeneratorPage id={this.id} />;
			case "Telstra\\ChannelPartners\\Pages\\BadgeGeneratorPage":
				return <BadgeGeneratorPage id={this.id} />;
			case "Telstra\\ChannelPartners\\Pages\\ContactUsPage":
				return <ContactUsPage id={this.id} />;
			default:
				return <></>;
		}
	}
}
// todo match groups with group names in admin.
NavigationModel.PermissionLevel = {
	ADMIN: 'ADMIN',
	STAFF: 'STAFF'
}