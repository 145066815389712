import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const shouldNotDisplayOn = ["/login", "/lostpassword*", "/register*", "/changepassword*", "/member/confirm*"];

const DesktopOnlyNotification = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const matches = shouldNotDisplayOn.filter(rule => {
            return new RegExp("^" + rule.replaceAll('*', '.*') + "$").test(location.pathname);
        })
        if (isMobile && matches.length === 0) {
            history.push('/mobile');
        }
    }, [isMobile, location.pathname])

    return null;

}
export default DesktopOnlyNotification;
