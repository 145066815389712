import axios from "axios";

export const defaultFetcher = (url) => axios.get(url).then(res => res.data);

export const fetcherWithIncludeRelatedHeaders = (url) => {
    return axios.get(url, {
        headers: {
            "X-IncludeRelated": `true`
        }
    }).then(res => res.data);
}