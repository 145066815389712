import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    message: null,
    action: null,
    isError: false,
    showHeader: true,
    onCloseCallback: null
}

const notificationSlice = createSlice({
    name: 'notification/notification',
    initialState,
    reducers: {
        openDialog(state, action) {
            return {
                isOpen: true,
                message: action.payload.message,
                action: action.payload.action,
                isError: action.payload.isError,
                headerText: action.payload.headerText,
                showHeader: action.payload.showHeader !== undefined ? action.payload.showHeader : true,
                onCloseCallback: action.payload.onCloseCallback
            }
        },
        closeDialog(state, action) {
            state.isOpen = false;
        },
    },
});

const {actions, reducer} = notificationSlice
// Extract and export each action creator by name
export const {openDialog, closeDialog} = actions
export default reducer;
