import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import apiService from '../../api/apiService';

export const getAccreditationPartnerAwards = createAsyncThunk('accreditation/partnerAwards', async () => {
	const response = await apiService.accreditationsGetPartnerAwards();
	return response.data;
});

const accreditationPartnerAwardsAdapter = createEntityAdapter({
	selectId: (accreditationPartnerAwards) => accreditationPartnerAwards.id
});

export const { selectAll: selectAccreditationPartnerAwards, selectById: selectAccreditationPartnerAwardsById }
	= accreditationPartnerAwardsAdapter.getSelectors(state => state.accreditationPartnerAwards);

const accreditationPartnerAwardsSlice = createSlice({
	name: 'accreditation/partnerAwards',
	initialState: accreditationPartnerAwardsAdapter.getInitialState(),
	reducers: {},
	extraReducers: {
		[getAccreditationPartnerAwards.fulfilled]: (state, action) => {
			accreditationPartnerAwardsAdapter.setAll(state, action.payload);
		}
	}
});

export default accreditationPartnerAwardsSlice.reducer;
