const devConfig = {
	url: 'http://php74.10.1.1.10.xip.io/telstra_channelpartners_ss48'
	 // url: 'https://staging.cms.channelpartners.rawideas.com'
};

const testingConfig = {
	url: 'https://testing-channelpartners.rawideas.com'
};

const stageConfig = {
	url: 'https://staging.cms.channelpartners.rawideas.com'
};

const prodConfig = {
	//url: 'https://cms.channelpartners.telstra.com' // TODO: change to this after the DNS gets added
	url: 'https://cms.channelpartners.rawideas.com'
};

const e = process.env.REACT_APP_ENV;
let c;

switch (e) {
	case 'staging':
		if(window.location.href.includes("testing-channelpartners-frontend")){
			c = testingConfig;
		} else {
			c = stageConfig;
		}
		break;
	case 'production':
		c = prodConfig;
		break;
	default:
		c = devConfig;
}
export default c;
