import React from "react";
import {StyledButton} from "./Button.style";

const Button = ({label, type, className, disabled, arrowRight, arrowLeft, onClick, variant, minWidth, ...props}) => {
    return <StyledButton
        type={type}
        className={className}
        disabled={disabled}
        arrowLeft={arrowLeft}
        arrowRight={arrowRight}
        onClick={onClick}
        variant={variant}
        minWidth={minWidth}
        {...props}
    >
        {arrowLeft && <i/>}<span>{label}</span>{arrowRight && <i/>}
    </StyledButton>
}
Button.defaultProps = {
    type: "button",
    className: "",
    variant: "contained",
    disabled: false,
    onClick: () => {}
};

export default Button;