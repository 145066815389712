import Header from './components/Header';
import Navigation from './components/Navigation';
import {useLocation} from 'react-router-dom';
import useBodyClass from '../../features/shared-components/useBodyClass';
import {noLogonRedirectNavigation} from '../../services/dynamicRouter/DynamicRouter';

function CP(props) {

    const location = useLocation();

    useBodyClass('layout-page');
    useBodyClass('layout-');

    return (
        <>
            <Header/>

            <div style={{display: "flex"}}>
                {!noLogonRedirectNavigation.find((route) => location.pathname.startsWith(route)) && <Navigation/>}

                <div className="wrapper">

                    <div className="mainWrapper">

                        {props.children}

                    </div>
                </div>
            </div>
        </>
    );
}

export default CP;
